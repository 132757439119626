import { Epic } from 'redux-observable';
import { isActionOf, getType } from 'typesafe-actions';
import { mergeMap, switchMap, mapTo, catchError, filter } from 'rxjs/operators';

// Utils
import { apiRequest, apiRequestError } from '../util/api';
import { getACObservable } from '../util/ActionCableObservable';
import {
  isNil,
  isNilOrEmpty,
  jsonConvert,
  getWSCallKey,
} from '../util/general';
// Constants
import { API_URL, API_METHOD_TYPE, WS_CHANNELS, WS_ACTIONS } from '../const/api';
import { STATUS } from '../const/app';
// Actions
import {
  checkoutActions,
  generalActions,
  settingsActions,
  basketActions,
  hotelActions,
} from '../actions';
// Models
import {
  BasketItemModel,
  BasketModel,
  BookingItemModel,
  BookingModel,
  HotelModel,
  RentalModel,
  TravelBookingModel,
  TripModel,
  RedeemingAllowanceModel,
} from '../models';
// Interfaces
import { RootAction, RootState } from '../interfaces';

export const initBasketEpic:Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(checkoutActions.initBasket)),
    switchMap((action) => {
      const checkoutObservable = getACObservable(WS_CHANNELS.BASKETS, action.payload.basketId, WS_ACTIONS.REBROADCAST, store$.value.adminUser.profile?.preference?.language);

      return checkoutObservable.pipe(
        switchMap((res:any) => {
          if (!isNil(res.basket)) {
            let actionsArr:RootAction[] = [];
            if (!isNilOrEmpty(res.basket.items)) {
              res.basket.items.map((item:any) =>
                actionsArr.push(checkoutActions.addBaksetItem(jsonConvert.deserializeObject(item, BasketItemModel)))
              );
            }

            const basket = jsonConvert.deserializeObject(res.basket, BasketModel);

            actionsArr = actionsArr.concat([
              checkoutActions.setBasket(basket),
              checkoutActions.setTrips(
                res.trips.map((trip:TripModel) => jsonConvert.deserialize(trip, TripModel))
              ),
              checkoutActions.setHotels(
                res.hotels.map(
                  (hotel:HotelModel) => jsonConvert.deserialize(hotel, HotelModel)
                )
              ),
              checkoutActions.setRentals(
                res.rentals.map(
                  (rental:RentalModel) => jsonConvert.deserialize(rental, RentalModel)
                )
              ),
              checkoutActions.setTravelBooking(
                // We only map it if res.travel_booking exists, TODO: improve?
                res.travel_booking && jsonConvert.deserializeObject(res.travel_booking, TravelBookingModel)
              ),
              checkoutActions.setSeatMapSelectorUrl(res.seat_map_selector_url),
              checkoutActions.setRedeemingAllowance(
                jsonConvert.deserializeObject(res.redeeming_allowance, RedeemingAllowanceModel)),
              settingsActions.setWSCallEnd(getWSCallKey(action.type, action.payload.basketId)),
              settingsActions.setWSCallEnd(
                getWSCallKey(getType(basketActions.addTripTariffsToBasketAsync.request))),
              settingsActions.setWSCallEnd(
                getWSCallKey(getType(hotelActions.addHotelFaresToBasketAsync.request))),

              generalActions.applyExtActionAsync.request({
                callback: action.payload.onSuccess,
                param: jsonConvert.deserialize(res.basket, BasketModel),
              }),
            ]);

            if (basket.status === STATUS.PREPARE_FINISHED) {
              actionsArr.push(
                settingsActions.setWSCallEnd(
                  getWSCallKey(
                    getType(checkoutActions.prepareBookingAsync.request),
                    action.payload.basketId,
                  ),
                ),
              );
            }

            if (basket.status === STATUS.CONFIRM_FINISHED) {
              actionsArr.push(
                settingsActions.setWSCallEnd(
                  getWSCallKey(
                    getType(checkoutActions.confirmBookingAsync.request),
                    action.payload.basketId,
                  ),
                ),
              );
            }

            return actionsArr;
          } else {
            return [generalActions.noOp()];
          }
        })
      );
    })
  );

export const prepareBookingEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(checkoutActions.prepareBookingAsync.request)),
    switchMap((action) => {
      return apiRequest(
          `${API_URL.BASKETS}/${action.payload.basketId}/prepare`,
          API_METHOD_TYPE.POST
        ).pipe(
          mergeMap((res:any) => {
            return [
              checkoutActions.initBookings(res.response.booking_id),
              generalActions.applyExtActionAsync.request({
                callback:action.payload.onSuccess, param:res.response,
              }),
            ];
          }),
          catchError((err) => apiRequestError(action.payload.onError, 'PrepareBookingError', err))
        );
    })
  );

export const confirmBookingEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(checkoutActions.confirmBookingAsync.request)),
    switchMap((action) =>
      apiRequest(
        `${API_URL.BASKETS}/${action.payload.basketId}/confirm`,
        API_METHOD_TYPE.POST,
        {
          travel_booking: {
            reference_code: action.payload.referenceCode,
            purpose_of_trip: action.payload.purposeOfTrip,
            cost_center_allocations_attributes: action.payload.costCenterAllocationAttributes,
            cost_unit_allocations_attributes: action.payload.costUnitAllocationAttributes,
            booking_items_attributes: action.payload.bookingItemsAttributes,
            remarks: action.payload.hotelRemarks ? action.payload.hotelRemarks : undefined,
            accounting_invoice_profile_id: action.payload.invoiceProfileId
              ? action.payload.invoiceProfileId
              : undefined,
          },
        }
      ).pipe(
        mapTo(generalActions.applyExtActionAsync.request({
          callback: action.payload.onSuccess, param: null,
        })),
        catchError((err) => apiRequestError(action.payload.onError, 'ConfirmBookingError', err))
      )
    )
  );

export const initBookingEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(checkoutActions.initBookings)),
    switchMap((action) => {
      const bookingObservable = getACObservable(WS_CHANNELS.BOOKINGS, action.payload.bookingId, WS_ACTIONS.REBROADCAST);

      return bookingObservable.pipe(
        switchMap((res:any) => {
          const actionsArr:RootAction[] = [];
          if (!isNilOrEmpty(res.booking.items)) {
            res.booking.items.map((item:any) =>
              actionsArr.push(checkoutActions.addBookingItem(jsonConvert.deserializeObject(item, BookingItemModel)))
            );
          }

          actionsArr.push(checkoutActions.setCurrentBooking(jsonConvert.deserializeObject(res.booking, BookingModel)));
          actionsArr.push(settingsActions.setWSCallEnd(getWSCallKey(action.type, action.payload.bookingId))),
          actionsArr.push(checkoutActions.setLastWSUpdated(Date.now()));

          return actionsArr;
        })
      );
    })
  );


export const submitSupportRequestEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(checkoutActions.submitSupportRequestAsync.request)),
    switchMap((action) =>
      apiRequest(
        `${API_URL.TRAVEL_BOOKINGS}/${action.payload.bookingId}/support_requests`,
        API_METHOD_TYPE.POST,
        {
          reason: action.payload.reason,
          user_comment: action.payload.userComment,
        }
      ).pipe(
        mapTo(generalActions.applyExtActionAsync.request({
          callback: action.payload.onSuccess, param: null,
        })),
        catchError((err) => apiRequestError(action.payload.onError, 'SubmitSupportRequestError', err))
      )
    )
  );
