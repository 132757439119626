import { differenceInYears } from 'date-fns';
import {
  isLength,
  isNilOrEmpty,
  startsWith,
  all,
  either,
  values,
  pipe,
  countBy,
  prop,
  ifElse,
  equals,
  defaultTo,
  reduce,
  partial,
  pluck,
  any,
  isNil,
} from '@src/shared/src/util/general';
import i18n from '@toolkit/util/i18n';

export const maxElements = (max: number, values: any[]) => {
  return values
    ? values.length > 5
      ? i18n.t('formsValidation.errorMessage.maxElements', { max })
      : undefined
    : undefined;
};

export const required = (value: string) =>
  isNilOrEmpty(value) ? i18n.t('formsValidation.errorMessage.required') : undefined;

export const olderThan18 = (value: Date) =>
  differenceInYears(new Date(), value) < 18
    ? i18n.t('formsValidation.errorMessage.olderThan18')
    : undefined;

export const mobilePhone = (value: string) => {
  if (isNilOrEmpty(value)) {
    return undefined;
  }
  const mobilePhoneRegEx = /^(\+?[0-9 \-()]){3,20}$/;
  return !mobilePhoneRegEx.test(value)
    ? i18n.t('formsValidation.errorMessage.mobilePhone')
    : undefined;
};

export const minLength = (value: string) =>
  !isLength(value, { min: 3 }) ? i18n.t('formsValidation.errorMessage.minLength') : undefined;

export const maxLength = (value: string) =>
  !isLength(value, { max: 250 })
    ? i18n.t('formsValidation.errorMessage.maxLength', { max: 250 })
    : undefined;

export const alphaNumeric = (value: string) => {
  const postalCodeRegEx = /^[a-z\d\s]+$/i;
  return !postalCodeRegEx.test(value)
    ? i18n.t('formsValidation.errorMessage.alphaNumeric')
    : undefined;
};
// TODO: make it more general so as to accept the error message
export const checked = (value: boolean) =>
  value ? undefined : i18n.t('checkoutPaymentInfo.errorMsgTooltip.acceptTerms');

export const minLen = (min: number, str: string) =>
  str
    ? !isLength(str, { min })
      ? i18n.t('formsValidation.errorMessage.minLength', { min })
      : undefined
    : undefined;

export const maxLen = (max: number, str: string) =>
  str
    ? !isLength(str, { max })
      ? i18n.t('formsValidation.errorMessage.maxLength', { max })
      : undefined
    : undefined;

export const shouldStartsWith = (searchString: string, str: string) =>
  str
    ? !startsWith(searchString, str)
      ? i18n.t('formsValidation.errorMessage.shouldStartWith', { searchString })
      : undefined
    : undefined;

export const checkSelectValueUniqueness = pipe(
  defaultTo([]),
  countBy(prop('selectValue')),
  values,
  all((val: number) => val === 1),
  ifElse(
    equals(true),
    () => undefined,
    () => i18n.t('checkoutPaymentAccountingForm.costCenter.error.sameTwice'),
  ),
);

export const checkSelectPercentage = pipe(
  defaultTo([{ percentageValue: 100 }]),
  reduce((tp, cv) => tp + (cv ? cv.percentageValue || 0 : 0), 0),
  ifElse(
    either(equals(100), equals(0)),
    () => undefined,
    () => i18n.t('checkoutPaymentAccountingForm.costCenter.error.sumNotComplete'),
  ),
);

export const checkRequiredCostCenter = pipe(
  defaultTo([]),
  ifElse(equals([]), () => i18n.t('formsValidation.errorMessage.required'), () => undefined),
);

export const checkNullCostCenter = pipe(
  defaultTo([]),
  pluck('selectValue'),
  any((val) => isNil(val)),
  ifElse(equals(true), () => i18n.t('formsValidation.errorMessage.required'), () => undefined),
);

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const passwordMinLen = partial(minLen, [6]);
