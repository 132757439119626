import { Observable } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

// Utils
import { appVersion } from './general';
import { errorLogger, ajaxErrorMsg, isAjaxErrorValid } from './errors';
// Constants
import { API_METHOD_TYPE, API_URL } from '../const/api';
import { ERROR_SEVERITY } from '../const/app';
// Actions
import { generalActions } from '../actions';
import { buildBaseAjaxError } from '@src/util/exceptions';
// Models
// Interfaces

/**
 * Returns an ajax observable
 * @param reqURL
 * @param reqType
 * @param reqData
 */
export const apiRequest = (
  reqURL: string,
  reqType: API_METHOD_TYPE = API_METHOD_TYPE.GET,
  reqData?: any): Observable<AjaxResponse> => {

  const headers: any = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-version': appVersion,
    'x-platform': 'web',
    'x-is-webview': !!((window as any).__IS_WEBVIEW),
    // This will be overwritten if the mobileview is in our mobile app
    'x-device': 'desktop/mobileview',
  };

  return ajax({
    url: reqURL,
    method: reqType,
    responseType: 'text',
    headers,
    body: reqData ? JSON.stringify(reqData) : undefined,
    withCredentials: true,
  }).pipe(
    map((res: any) => {
      try {
        const result: any = {
          response: JSON.parse(res.response),
        };
        return result;
      }
      catch (err) {
        return '{}';
      }
    })
  );
};

export const apiRequestError = (
  callback: Function,
  errorClassName: string,
  err: AjaxError,
  severity = ERROR_SEVERITY.WARNING) => {
  if (err instanceof AjaxError && isAjaxErrorValid(err)) {
    err = buildBaseAjaxError(err, errorClassName)
    errorLogger(String(err.status), err, severity)
  } else if (!(err instanceof AjaxError)) {
    // Non AjaxObservable based Ajax error. We specifiy a condition because otherwise we might get invalid AjaxError here (see isAjaxErrorValid for more info)
    errorLogger(errorClassName, err, severity)
  }
  return [
    generalActions.applyExtActionAsync.request({
      callback,
      param: ajaxErrorMsg(err),
    }),
  ];
};

export const apiURL = {
  basket: {
    prepare: (basketId: number) => `${API_URL.BASKETS}/${basketId}/prepare`,
    confirm: (basketId: number) => `${API_URL.BASKETS}/${basketId}/confirm`,
    removeItem: (basketId: number) => `${API_URL.BASKETS}/${basketId}/remove_items`,
    addTariffs: (basketId: number) => `${API_URL.BASKETS}/${basketId}`,
  },
  basketItem: {
    setSeatReservation: (basketItemId: number) => `${API_URL.BASKET_ITEMS}/${basketItemId}`,
  },
  organization: {
    fetch: (orgId: number) => `${API_URL.ORGANIZATIONS}/${orgId}`,
  },
  hotels: {
    setFavorite: (hotelId: string) => `${API_URL.HOTEL_DETAILS}/${hotelId}/favorite`,
  },
  search: {
    fetchEvents: (searchId: number) => `${API_URL.TRAVEL_SEARCH}/${searchId}/events.json`,
  },
};
