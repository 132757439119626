import { AjaxError } from 'rxjs/ajax';
import Bugsnag, { Event } from '@bugsnag/js';

// Utils
import {
  consoleLog,
  flatten,
  isNilOrEmpty,
  map,
  mapObjIndexed,
  pathOr,
  pipe,
  prop,
  replace,
  values,
} from './general';
// Constants
import {
  ERRORS,
  ERROR_SEVERITY,
} from '../const/app';

export const errorLogger = (
  title: string,
  err: any,
  severity?: ERROR_SEVERITY,
  groupedBy?: string,
) => {
  consoleLog(err);

  Bugsnag.notify(err, (event: Event) => {
    event.severity = severity || ERROR_SEVERITY.WARNING;
    event.context = title;
    if (!isNilOrEmpty(groupedBy)) {
      event.groupingHash = groupedBy;
    }
  })
};

const keyValueProd = (value: any[], key: string) =>
  map(
    pipe(
      prop('error'),
      replace(/\./g, ''), // Remove dots
      replace(/ /g, '.'), // Replace space by dot
      (val: string) => `${key}.${val}`
    )
  )(value);

const parseErrorJson = pipe(
  mapObjIndexed(keyValueProd),
  values,
  flatten
);

export const ajaxErrorMsg = (err: AjaxError) => {
  let errorObject;
  if (err && err.response) {
    try {
      errorObject = JSON.parse(err.response);
    } catch (e) {
      errorObject = err.response;
    }
  }
  const errorsKeys = parseErrorJson(pathOr(undefined, ['errors'])(errorObject));
  if (!isNilOrEmpty(errorsKeys)) {
    return errorsKeys;
  }

  switch (err.status) {
    case 404:
      return ERRORS.NOT_FOUND;
    case 401:
      return ERRORS.NOT_AUTHORIZED;
    case 422:
      return ERRORS.UNPROCESSABLE_ENTITY;
    default:
      return ERRORS.UNKNOWN_ERROR;
  }
};

/**
 * Filters out error which are cancelled by user through refresh or aborted via navigation.
 * Reduces the noise in bugsnag
 */
export const isAjaxErrorValid = (err: AjaxError) => err.response !== '' && err.status !== 0;
